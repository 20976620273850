<template>
  <div class="mb-4">
    <div class="text-center mb-3">
      <img src="@/assets/etherbit.svg" height="25">
    </div>
    <h1 class="h5 mb-1 text-center">
      {{title}}
    </h1>
    <p class="lead text-muted text-center">Last {{subtitle}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String
  }
}
</script>

<style lang="scss">
.lead {
  font-size: 1.1rem!important;
}
</style>
