import axios from 'axios'

// const BASE_URL = process.env.NODE_ENV === 'production'
//   ? 'https://dum-e.harshjv.com/api'
//   : 'http://localhost:8081/api'

const BASE_URL = 'https://dum-e.harshjv.com/api'

export default axios.create({
  baseURL: BASE_URL
})
