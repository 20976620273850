const formatterBig = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 0
})
const formatterSmall = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
})
const formatterTiny = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 5,
  minimumFractionDigits: 2
})

function inr (val) {
  if (val < 1) {
    return formatterTiny.format(val)
  }

  if (val < 10001) {
    return formatterSmall.format(val)
  }

  return formatterBig.format(val)
}

export default {
  filters: {
    inr (val) {
      const x = inr(val)

      return x.replace(/.00$/, '')
    },
    percentage (val) {
      return (Math.abs(Math.ceil(val * 100)) / 100) + '%'
    }
  }
}
