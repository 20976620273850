<template>
  <div class="table-responsive border">
    <table class="table mb-0">
      <thead class="thead-dark">
        <tr>
          <th scope="col">
            <span>Name</span>
          </th>
          <th scope="col" class="text-right">Price</th>
          <th scope="col" class="text-center">Chart</th>
          <th scope="col" class="text-right">Change</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in list" :key="type + '-' + item.id" :data-key="type + '-' + prefix + '-' + (idx + 1)">
          <td>
            <span class="d-flex">
              <img v-if="item.image" :src="item.image">
              <span>
                <strong>{{item.name}}</strong><br>
                <small class="text-muted">{{item.ticker}}</small>
              </span>
            </span>
          </td>
          <td class="text-right">{{convert(item.price || price[item.id]) | inr}}</td>
          <td class="text-center">
            <Peity
              v-if="charts[item.id]"
              type="line"
              :options="{ stroke: (item.change || change[item.id]) > 0 ? '#19af55' : '#dc3545', strokeWidth: 2, fill: (item.change || change[item.id]) > 0 ? 'url(#success-gradient)' : 'url(#danger-gradient)', width: 60, height: 30 }"
              :data="charts[item.id]" />
          </td>
          <td :class="{
            'text-right': true,
            'text-success': (item.change || change[item.id]) > 0,
            'text-danger': (item.change || change[item.id]) < 0
          }">
            <Caret /> {{(item.change || change[item.id]) | percentage}}
          </td>
        </tr>
      </tbody>
      <tfoot class="thead-dark">
        <tr>
          <th colspan="2" class="small"><span class="op1">@EtherbitHQ</span></th>
          <th colspan="2" class="text-right small"><span class="op1">www.etherbit.in</span></th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import Peity from 'vue-peity'
import Caret from '@/components/Icons/CaretDown'
import formatter from '@/mixins/formatter'

export default {
  components: {
    Caret,
    Peity
  },
  mixins: [formatter],
  props: {
    list: Array,
    type: String,
    prefix: String,
    duration: String,
    rate: Number,
    calculate: Boolean
  },
  data () {
    return {
      charts: {},
      change: {},
      price: {}
    }
  },
  async created () {
    const charts = {}

    await Promise.all(
      this.list.map(async item => {
        return this.$http('/markets/' + this.type + '/chart/' + item.id + '?duration=' + this.duration)
          .then(res => {
            const first = res.data.data.points[0]
            const last = res.data.data.points[res.data.data.points.length - 1]
            this.price[item.id] = last
            this.change[item.id] = Math.ceil(((last - first) / first) * 10000) / 100
            return res.data.data.points.toString()
          })
          .then(d => (charts[item.id] = d))
      })
    )

    this.charts = charts
  },
  methods: {
    convert (val) {
      if (!this.rate) return val
      return val * this.rate
    }
  }
}
</script>

<style>
.op1 {
  opacity: 0.6;
}
</style>
