import Vue from 'vue'
import App from './App.vue'
import Shorts from '@/plugins/Shorts'
import '../node_modules/bootstrap/scss/bootstrap.scss'

Vue.config.productionTip = false

Vue.use(Shorts)

new Vue({
  render: h => h(App)
}).$mount('#app')
